import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Fade from 'react-reveal/Fade';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
//import AppVideoFile from 'common/src/assets/image/ChrisBest Financeglob-04.mp4'//import AppVideoFile from 'common/src/assets/image/ChrisBest Financeglob.m4v'
import AppVideoFile from 'common/src/assets/image/Chrisharrisglob-042.gif'; //import AppVideoFile from 'common/src/assets/image/ChrisBest Financeglob.m4v'
import founderPhoto from 'common/src/assets/image/Photo_nasa_resize.jpg';

import { TransactionsWrapper, FeatureSection } from './transaction.style';

const AppVideo = ({
  row,
  col,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        TRANSACTIONS_FEATURE {
          title
          des
          image {
            publicURL
          }
        }
      }
    }
  `);

  const onFinish = async () => {
    navigate('/refi-pages/refi-application/');
  };

  return (
    <TransactionsWrapper id="MortgagesDontHaveToBeDifficult">
      <Container>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            {/* <FeatureSection> */}
            <p></p>
            <FeatureBlock
              title={
                <Heading content="Why I started Best Finance" {...title} />
              }
              description={
                <Text
                  content="Chris Lee Harris grew up on a farm in a small town in Tennessee, a working-class community with a population of 16K people. 
Determined to change his trajectory, Chris spent six months in a library studying successful people and reverse-engineering their methods. He wrote a strategic plan for his own success and returned to school at 25, studying engineering, math, physics, and computer science at UCLA with the goal of starting a tech company.
Chris secured a microcomputer and robotics internship at NASA and graduated at 31 with a degree in electrical engineering. By 33, he had invented a life-saving robotic machine and, at 34, founded a technology company with the purpose of streamlining the mortgage process, ultimately aiming to digitize and automate real estate capital markets.
Chris Lee Harris's journey exemplifies resilience, innovation, and a relentless pursuit of success, making significant contributions to technology and society."
                  {...description}
                />
              }
              /* button={
                <Button
                  onClick={onFinish}
                  title="See today's rates"
                  {...btnStyle}
                />
              } */
            />
          </Box>
          <Box
            className="colright"
            {...col}
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/*  <ReactPlayer
            className='react-player fixed-bottom'
            url={AppVideoFile}
			playing={true}
			loop={true}
			muted={true}
			width='60%'
            height='60%'
			playsinline
            controls = {false}
            /> */}
            <FeatureBlock
              description={
                <Text
                  content="Founder of Best Finance - Chris"
                  {...description}
                  fontWeight="600px"
                />
              }
              /* button={
                <Button
                  onClick={onFinish}
                  title="See today's rates"
                  {...btnStyle}
                />
              } */
            />
            <img src={founderPhoto} width="80%" height="80%" />
            {/* </FeatureSection> */}
          </Box>
        </Box>
      </Container>
    </TransactionsWrapper>
  );
};

// Transactions style props
AppVideo.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Transactions default style
AppVideo.defaultProps = {
  // Transactions section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Transactions section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Transactions section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
  },
  // Transactions section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['30px', '30px', '40px', '40px', '55px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    as: 'span',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['25px', '27px', '27px', '27px', '27px'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
  },
  // Transactions section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px',
  },
};

export default AppVideo;
